<template>
  <div id="app">
    <TopMenu/>
    <div class="container-fluid p-0">
      WIP
    </div>
  </div>
</template>

<script>
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

import TopMenu from '../components/TopMenu.vue'


export default {
  name: 'App',
  components: {
    TopMenu
  },
  data() {
      return {
          profiles: [
              { id: 1, name: 'ALL_METALS'}, 
              { id: 2, name: 'M5_METALS'},
              { id: 3, name: 'MT4'}, 
              { id: 4, name: 'MT4_MT5_METALS'}
          ]
      }
  },
  mounted() {
    console.log("I am in mounted!!!")
    this.settings = true
  },
  setup () {
    console.log("I am in setup!!!")
  }
}
</script>

<style>
  @import '../assets/styles/global.css';
</style>